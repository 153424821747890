/* You can add global styles to this file, and also import other style files */
$primary-color: #2e2e42;
$accent-color: #051d30;
$secondary-color: #8ab240;
$alert-color: #ba3939;
$white-color: #fff;
$box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.42);
$admin-background: #f6faf9;
$border: #ccc;
$background-light: #f9f9f9;
$overlay: rgba(15, 15, 16, 0.5);
$snackbar-bg: #323232;
$text-color: #07213c;
$icons-color: #77797b;
$shapes-color: #ddeaf3;
$lines-color: #c0c0c0;
$scrollbar-color: rgba(7, 178, 126, 0.04);
$secondary-color-opacity-5: rgba(46, 46, 66, 0.5);
$scroll-color: #e5e7e8;
$chat-color: #4776a7;
$chat-color-opacity-8: #4776a7cc;
$border-color: #99acc4;
$font-family-en: "Roboto", sans-serif;
$font-family-ar: "Tajawal", sans-serif;
:root {
  --secondary-color: #8ab240;
  --alert-color: #ba3939;
  --accent-color: #051d30;
}

$fontSize: 16;

html {
  font-size: $fontSize + px;
  scroll-behavior: smooth;
  &[lang="en"] {
    font-family: $font-family-en, sans-serif !important;
    direction: ltr !important;
  }
  &[lang="ar"] {
    font-family: $font-family-ar, sans-serif !important;
    direction: rtl !important;
  }
}

img {
  width: 100%;
  display: block;
}

/* Styles for large screens */
@media (min-width: 1200px) {
  $fontSize: 11.5;
  html {
    font-size: $fontSize + px !important;
  }
}

/* Styles for medium screens */
@media (max-width: 1199px) {
  $fontSize: 12;
  html {
    font-size: $fontSize + px !important;
  }
}

/* Styles for small screens */
@media (max-width: 576px) {
  $fontSize: 13.5;
  html {
    font-size: $fontSize + px !important;
  }
}

/* Styles for medium screens */
@media (min-width: 577px) and (max-width: 992px) {
  $fontSize: 13;
  html {
    font-size: $fontSize + px !important;
  }
}

// convert px to rem
@function pxToRem($pxSize) {
  $remValue: calc($pxSize / $fontSize);
  @return $remValue + rem;
}

@mixin textCustom($size, $line-height, $weight) {
  font-size: pxToRem($size) !important;
  line-height: pxToRem($line-height) !important;
  font-weight: $weight !important;
  font-family: $font-family-ar;
  letter-spacing: 0 !important;
}

.d-flex {
  display: flex !important;
  align-items: center;
}
.d-flex-center {
  @extend .d-flex;
  justify-content: center;
}

.d-flex-between {
  @extend .d-flex;
  justify-content: space-between;
}

.title-medium-3 {
  @include textCustom(22, 40, 500);
}
.label-small-2 {
  @include textCustom(18, 36, 400);
}

.display-small {
  @include textCustom(48, 72, 500);
}

.label-medium-2 {
  @include textCustom(18, 36, 500);
}

.body-small {
  @include textCustom(16, 32, 400);
}

.label-large {
  @include textCustom(20, 38, 700);
}

.label-medium {
  @include textCustom(20, 38, 500);
}

.title-large-3 {
  @include textCustom(22, 40, 700);
}

.label-small {
  @include textCustom(20, 38, 400);
}
@import "theme";
@import "mat-theme";

.field {
  display: flex;
  flex-direction: column;
  font-weight: normal;
  margin-top: pxToRem(32);
  text-align: right;
  input {
    @extend .label-medium-2;
    color: $text-color;
  }

  &__label {
    @extend .body-small;
    color: $text-color;
    direction: rtl;

    &__required {
      @extend .body-small;
      color: $alert-color;
      font-size: 2rem;
    }
  }
}

.btn {
  height: pxToRem(52) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: pxToRem(8);
  width: 100%;
  border: none;
  cursor: pointer;
}

.btn-secondary {
  background: $secondary-color;
  box-shadow: $box-shadow;
  color: $white-color !important;
  @extend .label-large;
}

.btn-primary {
  background-color: $primary-color !important;
  border-radius: pxToRem(8) !important;
  padding: pxToRem(8) !important;
  @extend .d-flex-between;
  color: $white-color !important;
  @extend .label-medium;
  width: auto;
  gap: pxToRem(8);
  direction: ltr;

  mat-icon {
    font-size: pxToRem(20) !important;
    height: pxToRem(20) !important;
    width: pxToRem(20) !important;
    margin: 0 !important;
    @extend .d-flex-center;
  }
}

.btn-primary-outline {
  @extend .label-medium;
  color: $primary-color !important;
  border: 1px solid $primary-color !important;
  background-color: transparent !important;
  gap: pxToRem(8);
  white-space: nowrap;
  justify-content: center !important;
  &::after {
    font-family: "Material Symbols Outlined";
    content: "download";
    position: relative;
    display: block;
    font-size: 22px;
    font-weight: 400;
    color: $secondary-color;
  }
}

.btn-cancel {
  @extend .label-medium;
  color: $text-color !important;
  background-color: transparent !important;
}
